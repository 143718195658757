import React, { useEffect, useState } from "react";

import PreviewPlaceholder from "components/Preview/Placeholder";
import PreviewPost from "components/Preview/Post";
import PreviewPage from "components/Preview/Page";


const Preview = () => {
  const query = new URLSearchParams(window.location.search) 

  const [postId,setPostId] = useState( query.get('id').match(/^\d+$/) ? query.get('id') : null )
  const [errors,setErrors] = useState( null )  
  const [type,setType] = useState( null )
  // const [status,setStatus] = useState( null )


  useEffect( () => {
    // detect if it's a page or a post
    const query = `
      query {
        postBy(postId: ${postId}) {
          postId
          uri
          title
        }
        pageBy(pageId: ${postId}) {
          pageId
          uri
          title
        }
      }
    `

    fetch(process.env.GATSBY_WP_GRAPHQL_ENDPOINT, { 
      method: "POST",
      headers: { 
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify( { query } ) 
    })
    .then( response => response.json() )
    .then( result => {
      if( result.errors ) {
        setErrors( result.errors )

        console.log( query )
        console.log( result.errors )
        return
      }

      if( result.data && result.data.postBy ) {
        setType( 'post' )
      }
      else if( result.data && result.data.pageBy ) {
        setType( 'page' )
      }
      else {
        setErrors( [{message: `Post or page for ID ${postId} not found`}] )
      }
    })

  }, [postId])


  switch( type ) {
    case "post":
      return <PreviewPost postId={postId} />
    
    case "page":
      return <PreviewPage postId={postId} />

    default:
      return <PreviewPlaceholder pageContext={false} errors={errors} postId={postId} />
  }

}

export default Preview

